import useDataContext from "context/data.hook";
import { BookProgressContext } from "./BookProgress.context"

/**
 * @description Armazena contexto do progresso da navegação dentro do Material Didático
 * @param param0 
 * @returns 
 */
const BookProgressProvider: React.FC = ({children}) => {
    
    const { totalProgress,setTotalProgress, visited, setVisited, routes, setRoutes } = useDataContext();

    return(
        <BookProgressContext.Provider
            value={{
                routes,
                visited,
                totalProgress,
                setRoutes,
                setVisited,
                setTotalProgress,
            }}>
                {children}
        </BookProgressContext.Provider>
    )
}

export default BookProgressProvider