import React from "react";
import Box from "@mui/material/Box";

import Header from "components/Header";
import Sidebar from "components/Sidebar";

import AuthenticatedRoutes from "routes/Authenticated";
import NotAuthenticatedRoutes from "routes/NotAuthenticated";

import "./styles.scss";
import { useParams } from "react-router-dom";
import useDataContext from "context/data.hook";
// import { verifyAccess } from "services/Login";
import ReactJson from 'react-json-view'

export default function Pages() {
  const params: any = useParams();
  const encodedString = params["*"];
  const parts = encodedString.split('/');
  const { isAuthenticated } = useDataContext();

  if (parts.length > 2) {
    const separatedString = parts.slice(2).join('/');
    console.log(separatedString);
    const accessEcossistema = JSON.parse(decodeURI(window.atob(separatedString)));
    sessionStorage.setItem('@params', JSON.stringify(accessEcossistema));
  }

  return (
    <>
      <Header />

      <Box display="flex">
        {isAuthenticated ? (
          <>
            <Box sx={{ maxWidth: { md: "21rem" }, minWidth: { md: "21rem" } }}>
              <Sidebar />
            </Box>

            <Box className="area_content">
              {/* <Box style={{ zIndex: "999999", position: "absolute", backgroundColor: "white", top: 10, left: 10 }}>
                <ReactJson
                  src={logs!}
                  theme="google"
                  iconStyle="triangle"
                  onEdit={(edit: any) => { }}
                  onDelete={((myObject: any) => { })}
                  onAdd={(add: any) => { }}
                  validationMessage={'¯\\_(ツ)_/¯'}
                  quotesOnKeys={false}
                  collapsed={false}
                  collapseStringsAfterLength={80}
                  style={{ padding: "10px", borderRadius: "10px", width: "800px", overflow: "none" }}
                />
              </Box> */}
              <Box style={{ paddingLeft: "1rem" }}>
                <AuthenticatedRoutes />
              </Box>
            </Box>
          </>
        ) : (
          <Box className="area_content no_auth">
            <NotAuthenticatedRoutes />
          </Box>
        )}
      </Box>
    </>
  );
}
