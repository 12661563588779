import { logs_api } from "services";
import { ILogsProps } from "./types/logs";

export class LogsServices {
    static async getLogsByRaAndIdSubjectAndIdIUnity(ra: string, idUnity: undefined | number) {
        if (!ra || !idUnity) {
            return {}
        }

        const { data } = await logs_api.get(`logs/ra/${ra}/unidade/${idUnity}`);
        return data;
    }

    static async getProgressByDisciplineAndRa(ra: string, idSubject: string) {
        if (!ra || !idSubject) {
            return 0
        }

        const { data } = await logs_api.get(`logs/ra/${ra}/disciplina/${idSubject}/progresso`);
        return data;
    }

    static async postLogs(props: ILogsProps) {
        return await logs_api.post('logs', {...props});
    }
}

//Funções
export const getLogsByRaAndIdSubjectAndIdIUnity = async (ra: string, idUnity: undefined | number, setDataLogs: any, recarregouPagina: boolean) => {
    try {
        if (recarregouPagina) {
            const result = await LogsServices.getLogsByRaAndIdSubjectAndIdIUnity(ra, idUnity);
            setDataLogs(result);
            return result;
        } else {
            const result = await LogsServices.getLogsByRaAndIdSubjectAndIdIUnity(ra, idUnity);
            setDataLogs(result);
        }
    } catch (error) {
        console.log(error);
    }
}

export const getProgressByDisciplineAndRa = async (ra: string, idSubject: string, setTotalProgress: any) => {
    try {
        const result = await LogsServices.getProgressByDisciplineAndRa(ra, idSubject);
        setTotalProgress(result);
    } catch (error) {
        console.log(error);
    }
}

export const postLogs = async (values: ILogsProps) => {
    try {
        await LogsServices.postLogs(values);
    } catch (error) {
        console.log(error);
    }
}