import { lazy, Suspense } from 'react';
import { Routes as Switch, Route } from 'react-router-dom';

import Loading from 'pages/components/Loading';

const Login = lazy(() => import('pages/Login'));
const NotFound = lazy(() => import('pages/NotFound'));

export default function NotAuthenticated() {
    return (
        <Suspense fallback={<Loading title="A página está sendo carregada..." />}>
            <Switch>
                <Route path='/:idSubject' element={<Login />}/>
                <Route path='/:idSubject/:tokenTutor' element={<Login />}/>
                <Route path='/:idSubject/ecossistema/:acess' element={<Login typeTheme='uninta'/>}/>
                <Route path='/:idSubject/faculdade_5_de_julho' element={<Login typeTheme='fal' />}/>
                <Route path='/:idSubject/faculdade_5_de_julho/:tokenTutor' element={<Login typeTheme='fal' />}/>
                <Route path='/:idSubject/faculdade_5_de_julho/ecossistema/:acess' element={<Login typeTheme='fal' />}/>
                <Route path='*' element={<NotFound />}/>
            </Switch>
        </Suspense>
    )
}