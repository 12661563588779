import { createContext, Dispatch, SetStateAction } from "react"

export interface BookProgressInterface {
    routes: any|any[];
    visited: any|any[];
    totalProgress: any|any[];
    setRoutes: Dispatch<SetStateAction<any|any[]>>;
    setVisited: Dispatch<SetStateAction<any|any[]>>;
    setTotalProgress: Dispatch<SetStateAction<any|any[]>>;
}

export const INITIAL_VALUES = {
    routes: [],
    visited: [],
    totalProgress: 0,
    setRoutes: () => null,
    setVisited: () => null,
    setTotalProgress: () => null,
}

export const BookProgressContext = createContext<BookProgressInterface>(INITIAL_VALUES);