import { useState, useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { uninta, fal, neutral } from 'theme';

import DataProvider from '../context/DataProvider';

import { CookiesProvider } from 'react-cookie';

import { ToastContainer } from 'react-toastify';
import Helmet from './Helmet';
import BookProgressProvider from 'context/BookProgressContext/BookProgress.provider';

export default function ProvidersGlobal({ children }: { children: React.ReactElement }) {
    const [theme, setTheme] = useState<'uninta' | 'fal' | 'neutral'>('neutral');

    /**
     * Este efeito buscará o tema
     */
    useEffect(() => {
        const storageSession: any = sessionStorage.getItem('@md_T');

        if (storageSession) {
            setTheme(window.atob(storageSession) as 'fal' | 'uninta' | 'neutral');
        }
        // eslint-disable-next-line
    }, []);

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme === 'neutral' ? neutral : theme === 'fal' ? fal : uninta}>
                <DataProvider>
                    <BookProgressProvider>
                        <CookiesProvider>
                            <Helmet />
                            {children}
                            <ToastContainer position='top-center' autoClose={2500} />
                        </CookiesProvider>
                    </BookProgressProvider>
                </DataProvider>
            </ThemeProvider>
        </BrowserRouter>
    )
}