import { createTheme } from '@mui/material/styles';

export const neutral = createTheme({
  palette: {
    primary: {
      main: '#FFF',
    },
    secondary: {
      main: '#292B2D',
    },
    success: {
      main: '#0A7541',
    }
  },
});

export const uninta = createTheme({
  palette: {
    primary: {
      main: '#B50000',
    },
    secondary: {
      main: '#292B2D',
    },
    success: {
      main: '#0A7541',
    }
  },
});

export const fal = createTheme({
  palette: {
		primary: {
			main: "#001D7E",
		},
		secondary: {
			main: "#DA7800",
		},
	},
});