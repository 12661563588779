/* eslint-disable array-callback-return */
import React, { 
  lazy, 
  useEffect, 
  useState 
} from "react";
import { 
  useNavigate, 
  useLocation 
} from "react-router-dom";
import { IListMenuNavigationProps, 
  INavigationProps 
} from "./types";

import "./styles.scss";

import ArticleOutlined        from "@mui/icons-material/ArticleOutlined";
import CheckCircle            from "@mui/icons-material/CheckCircle";
import RadioButtonUnchecked   from "@mui/icons-material/RadioButtonUnchecked";
import PlayCircleOutline      from "@mui/icons-material/PlayCircleOutline";
import SchoolOutlined         from "@mui/icons-material/SchoolOutlined";
import EmojiObjectsOutlined   from "@mui/icons-material/EmojiObjectsOutlined";
import CloudUploadOutlined    from "@mui/icons-material/CloudUploadOutlined";
import WbIncandescentOutlined from "@mui/icons-material/WbIncandescentOutlined";
import ThumbUpOutlined        from "@mui/icons-material/ThumbUpOutlined";
import BallotOutlined         from "@mui/icons-material/BallotOutlined";
import HelpOutline            from "@mui/icons-material/HelpOutline";
import BookOutlined           from "@mui/icons-material/BookOutlined";
import axios                  from "axios";
import useBookProgress        from "context/BookProgressContext/BookProgress.hook";
import HomeOutlined           from "@mui/icons-material/HomeOutlined";
import Loading                from "pages/components/Loading";
import SelectOfChangeUnity    from "./SelectOfChangeUnity";
import { onGoEcossistema }    from "../Header";
import useDataContext         from "context/data.hook";

const List              = lazy(() => import("@mui/material/List"));
const ListItem          = lazy(() => import("@mui/material/ListItem"));
const ListItemButton    = lazy(() => import("@mui/material/ListItemButton"));
const ListItemIcon      = lazy(() => import("@mui/material/ListItemIcon"));
const ListItemText      = lazy(() => import("@mui/material/ListItemText"));
const Toolbar           = lazy(() => import("@mui/material/Toolbar"));
const Box               = lazy(() => import("@mui/material/Box"));
const LinearProgress    = lazy(() => import("@mui/material/LinearProgress"));
const Typography        = lazy(() => import("@mui/material/Typography"));
const Divider           = lazy(() => import("@mui/material/Divider"));
const Button            = lazy(() => import("@mui/material/Button"));
const DialogAssessment  = lazy(() => import("./DialogAssessmentGuidelines"));
// const DialogDownload    = lazy(() => import("./DialogDownload"));

/**
 * 
 * Exibe menu de navegação lateral do Material Didático
 * @param {SetStateAction}  setOpen
 * @returns {React.JSX}
 */
export default function ListMenuNavigation({ setOpen } : IListMenuNavigationProps) {
  const {
    theme,
    subject,
    unitys,
    unitySelected,
    videos,
    // pratic_videos,
    script_pratical,
    depeeningKnowledge,
    learningResources,
    onHandleUpdateLogs,
    logs,
    insertRouteToUnity, 
    // dataLogs
  } = useDataContext();   // Hook para usar dados mantidos no ReactQuery
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { routes, setRoutes, totalProgress } = useBookProgress();  // Hook para usar dados do BookProgressContext

  const navigate                                                = useNavigate();    // Hook para navegação  -- Permite navegar com o React Router Dom
  const location                                                = useLocation();    // Hook para navegação  -- busca local atual
  // const [download, setDownload]                                 = useState(false);  // Gerencia o estado do Dialog para download
  const [openAssessmentGuidelines, setOpenAssessmentGuidelines] = useState(false);  // Gerencia o estado do dialogo de avaliação

  // let esclarecimento                                            = videos?.some(video => video?.type?.id === 1);
  // let pratica                                                   = videos?.some(video => video?.type?.id === 2);
  // let videoAula                                                 = videos?.some(video => video?.type?.id === 3);
  // let ideiaCentral                                              = videos?.some(video => video?.type?.id === 4);
  // let laboratórioVirtual                                        = videos?.some(video => video?.type?.id === 7);
  // let laboratório                                              = videos?.some(video => video?.type?.id === 6);

  /**
   * Function for verify if the item is active
   */
  function isItemNavigateActive(route: string): boolean {
    if (location.pathname === route) {
      return true;
    }
    return false;
  };

    /**
   * Este método remove as credenciais da sessionStorage,
   * removendo assim o usuário conectado
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function signOut() {
    sessionStorage.removeItem('@md_S');
    sessionStorage.removeItem('@md_AS');

    setTimeout(() => {
        window.location.href = `/${subject?.id}`;
    }, 100);
  }

  /**
   * @description Método para gerenciar navegação e sincronização de logs e progresso
   * @param {string} url 
   * @param {number|string} pathActived 
   * @param {number|string} subpathActived 
   * @param {any} typeLog 
   * @param {any} resource
   * @param {any} changed  
   * @param {Boolean}  unity
   * @returns 
   */
  function navigation ({ url, pathActived, typeLog, subpathActived = 0, resource}: INavigationProps) {
    insertRouteToUnity(unitySelected.id,{url:url,path: pathActived, subpath: subpathActived})
    // O código a seguir permanece inalterado
    navigate(url);
    setOpen && setOpen(false);
    
    onHandleUpdateLogs({typeLog: typeLog, resource: resource});
  }

  /**
   * Envia um novo log ao submeter um novo documento para o Aprofundando o Conhecimento
   */
  useEffect(()=>{
    if(
      logs?.log_info
      && logs?.log_info["AprofundandoNoConhecimento"]
      && logs?.log_info["AprofundandoNoConhecimento"]?.length === Array(depeeningKnowledge).length
    ) {
      /**
       * Realiza a tentativa e em caso de falha exibe o erro no console da página
       * Este comportamento deve ser modificado no futuro, inserindo conexão com uma ferramenta
       * de monitoramento como o Sentry.io
       */
      try {
          axios.post('https://homolog-api-logger.aiamis.com.br/ecossistema/logs',
              {
                  ra: JSON.parse(window.atob(sessionStorage.getItem('@md_S') as string)).ra,
                  module: 'MATERIAL_DIDATICO',
                  evento: {
                      label: 'USUARIO_ACESSA_RECURSO_APROF_CONHECIMENTO',
                      log_info: {
                          ra: JSON.parse(window.atob(sessionStorage.getItem('@md_S') as string)).ra,
                          user_type: JSON.parse(window.atob(sessionStorage.getItem('@md_S') as string)).user.typeUser,
                          info: JSON.parse(window.atob(sessionStorage.getItem('@md_S') as string)).user.info
                      }
                  },
                  metadata: JSON.parse(window.atob(sessionStorage.getItem('@md_S') as string)).metadata,
              }
          );
      } catch(err) {
          console.error(err);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

/**
 * Gerar objeto de rotas da unidade que servirá como "gabarito" da navegação
 * Executar sempre no primeiro carregamento, buscando as rotas existentes e as adicionando a um array plano
 */
  useEffect(() => {

    if(unitys && unitySelected && (unitySelected !== undefined || unitySelected !== null) && videos && learningResources) {
      
        var vt: any[]               = [];           // Videoaulas normais
        var vp: any[]               = [];           // Videoaula prática
        var rp: any[]               = [];           // Roteiro para aula pratica
        var vd: any[]               = [];           // Aprofundando o conhecimento
        var ra: any[]               = [];           // recursos aprendizagem

        // Adiciona o aprofundando conhecimento na posição inicial caso existam vídeos
        if(depeeningKnowledge && depeeningKnowledge.length > 0) {
          vd.unshift({url: "/aprofundando-conhecimento",pathActived: 7, subpathActived: depeeningKnowledge.map(item => item.id)});   
        }

        if (learningResources && learningResources.length > 0) {
            ra.unshift({url: "/recursos-aprendizagem", pathActived: 2, subpathActived: learningResources.map(item => item.id)})
        }

        if (videos) {
          // Preenche o array vazio com os videos da unidade caso existam
          vt = videos.filter((video, index) => {
            if(video.type.id) {
              return video
            }
          }).map((video, index)=>{
            return {url: "/videoaulas/"+ video.id, pathActived: 3, subpathActived: index}
          });

          // Preenche o array vazio com os videos praticos caso existam
          vp = videos.filter((praticVideo: any, index) => {
            if (praticVideo.type.id === 6) {
              return praticVideo
            }
          }).map((video, index)=>{
            return {url: "/aula-pratica/"+ video.id, pathActived: 10, subpathActived: index}
          })

          // Caso existam videos práticos ele adiciona o roteiro-prática ao array
          if(vp.length > 0){
            vp.unshift({ url: "/roteiro-pratica", pathActived: 9});
          }
          if(vp.length > 0){
            rp.unshift({ url: "/roteiro-pratica", pathActived: 9});
          }
        }

        if(unitySelected.id !== undefined && unitySelected.titulo_unidade !== "") {
          var unityRoutes = {
            id_unidade: unitySelected.id,
            titulo_unidade: unitySelected.titulo_unidade,
            routes: [
              { url: "/resumo-unidade", pathActived: 1},
              ...ra,
              ...vt,
              { url: "/guia-estudos", pathActived: 4},
              { url: "/partilhando-ideias", pathActived: 5},
              { url: "/contribuindo-documento", pathActived: 6},
              ...vd,
              { url: "/avaliar-unidade", pathActived: 8},
              ...rp,
              // ...vp,
            ]
          }
          
          if(unitySelected.id === unitys[0].id)  {
            unityRoutes.routes.unshift({ url: "/como-estudar", pathActived: 0 })
          }
          setRoutes([unityRoutes])

          // if(unitySelected.id === unitys[0].id)  {
          //   insertRouteToUnity(unitySelected.id, { url: "/como-estudar", path: 0, subpath: 0})
          // }  else if(unitySelected.id !== unitys[0].id) {
          //   insertRouteToUnity(unitySelected.id, { url: "/resumo-unidade", path: 1, subpath: 0})
          //   return;
          // }
        }
      } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[videos, unitys, unitySelected, depeeningKnowledge, learningResources])

  // Primeiro, organize os vídeos em categorias
  const categorizedVideos: any = {};

  videos?.forEach((video: any) => {
    if (video?.type?.video_lab_mat_didatico === 1) {
      if (!categorizedVideos["LABORATÓRIOS"]) {
        categorizedVideos["LABORATÓRIOS"] = [];
      }
      categorizedVideos["LABORATÓRIOS"].push(video);
    } else {
      const categoryName = video.type.nome;
      if (!categorizedVideos[categoryName]) {
        categorizedVideos[categoryName] = [];
      }
      categorizedVideos[categoryName].push(video);
    }
  });

  // Agora, mapeie as categorias para criar a listagem final
  const finalListing = Object.entries(categorizedVideos).map(([category, videosInCategory]: any) => (
    <>
      <ListItem>
        <Typography className="title_navigation" variant="subtitle2">
          {category}
        </Typography>
      </ListItem>

      <Divider />

      {videosInCategory?.map((video: any, index: any) => (
        <ListItem className={`item_nav ${isItemNavigateActive(`/videoaulas/${video?.id}`) ? "active" : ""} ${theme}`} key={video?.id}>
          <ListItemButton
            onClick={() =>
              navigation({
                url: `/videoaulas/${video?.id}`,
                pathActived: 3,
                typeLog: "Videoaulas",
                subpathActived: index, resource:[video?.id]
              })
            }
          >
            <ListItemIcon>
              {logs?.log_info &&
              Array.isArray(logs?.log_info["Videoaulas"]) &&
              logs?.log_info["Videoaulas"].includes(video?.id) ? (
                <CheckCircle />
              ) : (
                <RadioButtonUnchecked />
              )}

              <PlayCircleOutline />
            </ListItemIcon>
              <div>
                <span style={{fontWeight: "bold"}}>{video.type.nome} </span>
                <ListItemText primary={video.titulo} />
              </div>
          </ListItemButton>
        </ListItem>
      ))}

      <Divider />

    </>
  ));
    
  // Renderiza componente React.JSX que exibe a navegação
  return (
    <React.Fragment>
      <List className="drawer_menu">
        <Toolbar style={{ minWidth: "20rem" }} />

        <ListItem>
          <Box className="area_nav_info">
            <Box>
              <Button
                startIcon={<HomeOutlined />}
                sx={{ display: { md: "none" } }}
                variant="outlined"
                onClick={onGoEcossistema}
              >
                Voltar
              </Button>

              <Typography variant="subtitle1">{subject?.nome}</Typography>
            </Box>

            <Box>
              <LinearProgress
                className="progress"
                variant="determinate"
                value={totalProgress}
                // value={disciplineProgress}
                color="success"
              />

              <Typography className="text_progress">
                {totalProgress}% completo
                {/* {disciplineProgress}% completo */}
              </Typography>

              {/* {unitys &&
                Array.isArray(unitys) &&
                unitys?.indexOf(unitySelected) === unitys?.length - 1 && (
                  <Button
                    startIcon={<StarBorder />}
                    variant="contained"
                    fullWidth
                    color="secondary"
                    onClick={() =>
                      navigation({
                        url: "/avaliar-disciplina",
                        pathActived: -1,
                      })
                    }
                  >
                    Avaliar Disciplina
                  </Button>
                )} */}
            </Box>
          </Box>
        </ListItem>

        <Divider />

        <ListItem>
          <Box className="area_nav_info">
            {unitySelected?.id ? (
              <SelectOfChangeUnity navigation={navigation} />
            ) : (
              <Loading />
            )}

            <Typography variant="subtitle2">
              {unitySelected?.titulo_unidade}
            </Typography>
          </Box>
        </ListItem>

        <Divider />

        {/* Navigations */}
        <ListItem className="item_nav optional">
          <ListItemButton onClick={() => setOpenAssessmentGuidelines(true)}>
            <ListItemIcon>
              <HelpOutline />

              <BookOutlined />
            </ListItemIcon>

            <ListItemText primary="Orientações para Avaliações" />
          </ListItemButton>
        </ListItem>

        {unitys &&
          Array.isArray(unitys) &&
          unitys?.indexOf(unitySelected) === 0 && (
            <ListItem
              className={`item_nav ${isItemNavigateActive("/como-estudar") ? "active" : ""} ${theme}`}
            >
              <ListItemButton
                onClick={() =>
                  navigation({ 
                    url: "/como-estudar", 
                    pathActived: 0,
                    typeLog: "ComoEstudar"
                  })
                }
              >
                <ListItemIcon>
                    <CheckCircle />

                  <ArticleOutlined />
                </ListItemIcon>

                <ListItemText primary="Como estudar" />
              </ListItemButton>
            </ListItem>
          )}

        <ListItem
          className={`item_nav ${
            isItemNavigateActive("/resumo-unidade") ? "active" : ""
          } ${theme}`}
        >
          <ListItemButton
            onClick={() =>
              navigation({
                url: "/resumo-unidade",
                pathActived: 1,
                typeLog: "ResumoDaUnidade",
              })
            }
          >
            <ListItemIcon>
              {/* {Array.isArray(unitys) !== undefined && unitys[0].id !== unitySelected.id ? 
                (<CheckCircle />) : (<>
                </>)} */}
              {logs?.log_info && logs?.log_info["ResumoDaUnidade"] ? (<CheckCircle />) : ( <RadioButtonUnchecked /> )}

              <ArticleOutlined />
            </ListItemIcon>

            <ListItemText primary="Resumo da Unidade" />
          </ListItemButton>
        </ListItem>

        <ListItem
          className={`item_nav ${
            isItemNavigateActive("/recursos-aprendizagem") ? "active" : ""
          } ${theme}`}
        >
          <ListItemButton
            onClick={() =>
              navigation({ 
                url: "/recursos-aprendizagem", 
                pathActived: 2, 
                typeLog: "RecursosDeAprendizagem",
                subpathActived: learningResources.map(item => item.id),
                resource: learningResources.map(item => item.id)
              })
            }
          >
            <ListItemIcon>
              {logs?.log_info &&
              logs?.log_info["RecursosDeAprendizagem"] &&
              logs?.log_info["RecursosDeAprendizagem"]?.length ===
                learningResources?.length ? (
                <CheckCircle />
              ) : (
                <RadioButtonUnchecked />
              )}

              <ArticleOutlined />
            </ListItemIcon>

            <ListItemText primary="Recursos de aprendizagem" />
          </ListItemButton>
        </ListItem>

        <Divider />

        {finalListing}

        {/* <ListItem>
          <Typography className="title_navigation" variant="subtitle2">
            VideoAulas
          </Typography>
        </ListItem>

        <Divider />

        {Boolean(videos) && (
            <> 
              {videos?.map((video: any, index) => {
                  return(
                    <ListItem
                      className={`item_nav ${
                        isItemNavigateActive(`/videoaulas/${video?.id}`) ? "active" : ""
                      } ${theme}`}
                      key={video?.id}
                    >
                      <ListItemButton
                        onClick={() =>
                          navigation({
                            url: `/videoaulas/${video?.id}`,
                            pathActived: 3,
                            typeLog: "Videoaulas",
                            subpathActived: index, resource:[video?.id]
                          })
                        }
                      >
                        <ListItemIcon>
                          {logs?.log_info &&
                          Array.isArray(logs?.log_info["Videoaulas"]) &&
                          logs?.log_info["Videoaulas"].includes(video?.id) ? (
                            <CheckCircle />
                          ) : (
                            <RadioButtonUnchecked />
                          )}
        
                          <PlayCircleOutline />
                        </ListItemIcon>
                          <div>
                            <span style={{fontWeight: "bold"}}>{video.type.nome} </span>
                            <ListItemText primary={video.titulo} />
                          </div>
                      </ListItemButton>
                    </ListItem>
                  )
                })}
          </>        
        )} */}

        <Divider />

        {/* {Boolean(videos) && (
          ideiaCentral && (
            <> 
              <ListItem>
                <Typography className="title_navigation" variant="subtitle2">
                    Ideia Central
                </Typography>
              </ListItem>
      
              <Divider />
              {videos?.map((video: any, index) => {
                if (video?.type?.id === 4) {
                  return(
                    <ListItem
                      className={`item_nav ${
                        isItemNavigateActive(`/videoaulas/${video?.id}`) ? "active" : ""
                      } ${theme}`}
                      key={video?.id}
                    >
                      <ListItemButton
                        onClick={() =>
                          navigation({
                            url: `/videoaulas/${video?.id}`,
                            pathActived: 3,
                            typeLog: "Videoaulas",
                            subpathActived: index, resource:[video?.id]
                          })
                        }
                      >
                        <ListItemIcon>
                          {logs?.log_info &&
                          Array.isArray(logs?.log_info["Videoaulas"]) &&
                          logs?.log_info["Videoaulas"].includes(video?.id) ? (
                            <CheckCircle />
                          ) : (
                            <RadioButtonUnchecked />
                          )}
        
                          <PlayCircleOutline />
                        </ListItemIcon>
        
                        <ListItemText primary={video.titulo} />
                      </ListItemButton>
                    </ListItem>
                  )
                }})}
            <Divider />
          </>)          
        )}

        {Boolean(videos) && (
          videoAula && (
            <> 
              <ListItem>
                <Typography className="title_navigation" variant="subtitle2">
                  VideoAula
                </Typography>
              </ListItem>
      
              <Divider />

              {videos.map((video: any, index) => {
                if (video.type.id === 3) {
                  return(
                    <ListItem
                      className={`item_nav ${
                        isItemNavigateActive(`/videoaulas/${video?.id}`) ? "active" : ""
                      } ${theme}`}
                      key={video?.id}
                    >
                      <ListItemButton
                        onClick={() =>
                          navigation({
                            url: `/videoaulas/${video?.id}`,
                            pathActived: 3,
                            typeLog: "Videoaulas",
                            subpathActived: index, resource:[video?.id]
                          })
                        }
                      >
                        <ListItemIcon>
                          {logs?.log_info &&
                          Array.isArray(logs?.log_info["Videoaulas"]) &&
                          logs?.log_info["Videoaulas"].includes(video?.id) ? (
                            <CheckCircle />
                          ) : (
                            <RadioButtonUnchecked />
                          )}
        
                          <PlayCircleOutline />
                        </ListItemIcon>
        
                        <ListItemText primary={video.titulo} />
                      </ListItemButton>
                    </ListItem>
                  )
              }})}
            <Divider />
          </>)          
        )}

        {Boolean(videos)&& (
          esclarecimento && (
            <> 
              <ListItem>
                <Typography className="title_navigation" variant="subtitle2">
                  Esclarecimento de dúvidas
                </Typography>
              </ListItem>
      
              <Divider />
              {videos?.map((video: any, index) => {
                if (video?.type?.id === 1) {
                  return(
                    <ListItem
                      className={`item_nav ${
                        isItemNavigateActive(`/videoaulas/${video?.id}`) ? "active" : ""
                      } ${theme}`}
                      key={video?.id}
                    >
                      <ListItemButton
                        onClick={() =>
                          navigation({
                            url: `/videoaulas/${video?.id}`,
                            pathActived: 3,
                            typeLog: "Videoaulas",
                            subpathActived: index, resource:[video?.id]
                          })
                        }
                      >
                        <ListItemIcon>
                          {logs?.log_info &&
                          Array.isArray(logs?.log_info["Videoaulas"]) &&
                          logs?.log_info["Videoaulas"].includes(video?.id) ? (
                            <CheckCircle />
                          ) : (
                            <RadioButtonUnchecked />
                          )}
        
                          <PlayCircleOutline />
                        </ListItemIcon>
        
                        <ListItemText primary={video.titulo} />
                      </ListItemButton>
                    </ListItem>
                  )
              }})}
            <Divider />
          </>)          
        )}

        {Boolean(videos) && (
          pratica && (
            <> 
              <ListItem>
                <Typography className="title_navigation" variant="subtitle2">
                  Na prática
                </Typography>
              </ListItem>
      
              <Divider />
              {videos?.map((video: any, index) => {
                if (video?.type?.id === 2) {
                  return(
                    <ListItem
                      className={`item_nav ${
                        isItemNavigateActive(`/videoaulas/${video?.id}`) ? "active" : ""
                      } ${theme}`}
                      key={video?.id}
                    >
                      <ListItemButton
                        onClick={() =>
                          navigation({
                            url: `/videoaulas/${video?.id}`,
                            pathActived: 3,
                            typeLog: "Videoaulas",
                            subpathActived: index, resource:[video?.id]
                          })
                        }
                      >
                        <ListItemIcon>
                          {logs?.log_info &&
                          Array.isArray(logs?.log_info["Videoaulas"]) &&
                          logs?.log_info["Videoaulas"].includes(video?.id) ? (
                            <CheckCircle />
                          ) : (
                            <RadioButtonUnchecked />
                          )}
        
                          <PlayCircleOutline />
                        </ListItemIcon>
        
                        <ListItemText primary={video.titulo} />
                      </ListItemButton>
                    </ListItem>
                  )
              }})}
          <Divider />
          </>)          
        )}

        {Boolean(videos) && (
          laboratórioVirtual && (
            <> 
              <ListItem>
                <Typography className="title_navigation" variant="subtitle2">
                  Laboratório virtual
                </Typography>
              </ListItem>
      
              <Divider />
              {videos?.map((video: any, index) => {
                if (video?.type?.id === 7) {
                  return(
                    <ListItem
                      className={`item_nav ${
                        isItemNavigateActive(`/videoaulas/${video?.id}`) ? "active" : ""
                      } ${theme}`}
                      key={video?.id}
                    >
                      <ListItemButton
                        onClick={() =>
                          navigation({
                            url: `/videoaulas/${video?.id}`,
                            pathActived: 3,
                            typeLog: "Videoaulas",
                            subpathActived: index, resource:[video?.id]
                          })
                        }
                      >
                        <ListItemIcon>
                          {logs?.log_info &&
                          Array.isArray(logs?.log_info["Videoaulas"]) &&
                          logs?.log_info["Videoaulas"].includes(video?.id) ? (
                            <CheckCircle />
                          ) : (
                            <RadioButtonUnchecked />
                          )}
        
                          <PlayCircleOutline />
                        </ListItemIcon>
        
                        <ListItemText primary={video.titulo} />
                      </ListItemButton>
                    </ListItem>
                  )
              }})}
            <Divider />
          </>)          
        )} */}

        <ListItem>
          <Typography className="title_navigation" variant="subtitle2">
            Interagindo
          </Typography>
        </ListItem>

        <Divider />

        <ListItem
          className={`item_nav ${
            isItemNavigateActive("/guia-estudos") ? "active" : ""
          } ${theme}`}
        >
          <ListItemButton
            onClick={() =>
              navigation({
                url: "/guia-estudos",
                pathActived: 4,
                typeLog: "GuiaDeEstudos",
              })
            }
          >
            <ListItemIcon>
              {logs?.log_info && logs?.log_info["GuiaDeEstudos"] ? (
                <CheckCircle />
              ) : (
                <RadioButtonUnchecked />
              )}

              <SchoolOutlined />
            </ListItemIcon>

            <ListItemText primary="Guia de estudos" />
          </ListItemButton>
        </ListItem>

        <ListItem
          className={`item_nav ${
            isItemNavigateActive("/partilhando-ideias") ? "active" : ""
          } ${theme}`}
        >
          <ListItemButton
            onClick={() =>
              navigation({
                url: "/partilhando-ideias",
                pathActived: 5,
                typeLog: "PartilhandoIdeias",
              })
            }
          >
            <ListItemIcon>
              {logs?.log_info && logs?.log_info["PartilhandoIdeias"] ? (
                <CheckCircle />
              ) : (
                <RadioButtonUnchecked />
              )}

              <EmojiObjectsOutlined />
            </ListItemIcon>

            <ListItemText primary="Partilhando ideias" />
          </ListItemButton>
        </ListItem>

        <ListItem
          className={`item_nav ${
            isItemNavigateActive("/contribuindo-documento") ? "active" : ""
          } ${theme}`}
        >
          <ListItemButton
            onClick={() =>
              navigation({
                url: "/contribuindo-documento",
                pathActived: 6,
                typeLog: "ContribuaComUmDocumento",
              })
            }
          >
            <ListItemIcon>
              {logs?.log_info && logs?.log_info["ContribuaComUmDocumento"] ? (
                <CheckCircle />
              ) : (
                <RadioButtonUnchecked />
              )}

              <CloudUploadOutlined />
            </ListItemIcon>

            <ListItemText primary="Contribua com um documento" />
          </ListItemButton>
        </ListItem>

        {Array.isArray(depeeningKnowledge) && depeeningKnowledge?.length > 0 && (
          <>
          <ListItem
            className={`item_nav ${
              isItemNavigateActive("/aprofundando-conhecimento") ? "active" : ""
            } ${theme}`}
          >
            <ListItemButton
              onClick={() =>
                navigation({
                  url: "/aprofundando-conhecimento",
                  pathActived: 7, 
                  typeLog: "AprofundandoNoConhecimento",
                  subpathActived: depeeningKnowledge.map(item => item.id),
                  resource: depeeningKnowledge.map(item => item.id)
                })
              }
            >
              <ListItemIcon>
                {logs?.log_info &&
                logs?.log_info["AprofundandoNoConhecimento"] &&
                logs?.log_info["AprofundandoNoConhecimento"]?.length ===
                  depeeningKnowledge?.length ? (
                  <CheckCircle />
                ) : (
                  <RadioButtonUnchecked />
                )}

                <WbIncandescentOutlined />
              </ListItemIcon>

              <ListItemText primary="Aprofundando o conhecimento" />
            </ListItemButton>
          </ListItem>
          {/* <pre>
            {JSON.stringify(depeeningKnowledge, null, 4)}
          </pre> */}
          </>
        )}

        <ListItem
          className={`item_nav ${
            isItemNavigateActive("/avaliar-unidade") ? "active" : ""
          } ${theme}`}
        >
          <ListItemButton
            onClick={() =>
              navigation({
                url: "/avaliar-unidade",
                pathActived: 8,
                typeLog: "AvaliarUnidade",
              })
            }
          >
            <ListItemIcon>
              {logs?.log_info && logs?.log_info["AvaliarUnidade"] ? (
                <CheckCircle />
              ) : (
                <RadioButtonUnchecked />
              )}

              <ThumbUpOutlined />
            </ListItemIcon>

            <ListItemText primary="Avaliar a unidade" />
          </ListItemButton>
        </ListItem>

        <Divider />

        {(
          // (
          script_pratical && script_pratical.guiaPraticoUrl
          // ) || pratic_videos?.length > 0
        ) && (
          <>
            <ListItem>
              <Typography className="title_navigation" variant="subtitle2">
                Orientações para prática
              </Typography>
            </ListItem>

            <Divider />

            {script_pratical && script_pratical.guiaPraticoUrl && (
              <ListItem
                className={`item_nav ${
                  isItemNavigateActive("/roteiro-pratica") ? "active" : ""
                } ${theme}`}
              >
                <ListItemButton
                  onClick={() =>
                    navigation({
                      url: "/roteiro-pratica",
                      pathActived: 9,
                      typeLog: "RoteiroParaAulaPrática",
                    })
                  }
                >
                  <ListItemIcon>
                    {logs?.log_info &&
                    logs?.log_info["RoteiroParaAulaPrática"] ? (
                      <CheckCircle />
                    ) : (
                      <RadioButtonUnchecked />
                    )}

                    <BallotOutlined />
                  </ListItemIcon>

                  <ListItemText primary="Roteiro para aula prática" />
                </ListItemButton>
              </ListItem>
            )}
            
            {/* {Boolean(videos) &&
              videos?.map((praticVideo: any, index) => {
                if (praticVideo.type.id === 6) {
                  return (
                    <ListItem
                      className={`item_nav ${isItemNavigateActive(`/aula-pratica/${praticVideo.id}`) ? "active" : ""} ${theme}`}
                      key={praticVideo?.id}
                    >
                      <ListItemButton
                        onClick={() => {
                          // localStorage.setItem("@video-pratico", JSON.stringify(praticVideo));
                          navigation({
                            url: `/aula-pratica/${praticVideo.id}`,
                            pathActived: 10,
                            typeLog: "VídeosParaAulaPrática",
                            subpathActived: index, resource:[praticVideo.id]
                          })
                        }
                        }
                      >
                        <ListItemIcon>
                          {logs?.log_info &&
                          Array.isArray(logs?.log_info["VídeosParaAulaPrática"]) &&
                          logs?.log_info["VídeosParaAulaPrática"].includes(
                            praticVideo?.id
                          ) ? (
                            <CheckCircle />
                          ) : (
                            <RadioButtonUnchecked />
                          )}

                          <PlayCircleOutline />
                        </ListItemIcon>

                        <ListItemText primary={praticVideo.titulo} />
                      </ListItemButton>
                    </ListItem>
                  )
                }
              })} */}
          </>
        )}
      </List>

      <DialogAssessment
        open={openAssessmentGuidelines}
        setOpen={setOpenAssessmentGuidelines}
      />
    </React.Fragment>
  );
}
