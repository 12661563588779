/* eslint-disable array-callback-return */
import Button                                                   from '@mui/material/Button';
import ArrowForwardIosOutlined                                  from '@mui/icons-material/ArrowForwardIosOutlined';
import useDataContext                                           from "context/data.hook";
import './styles.scss';

export default function BtnNextStep() {
    const { nextStep }                                          = useDataContext();

    return (
        <Button
            className="btn_next_step"
            variant="contained"
            color="secondary"
            endIcon={<ArrowForwardIosOutlined />}
            onClick={nextStep}
            data-web="Próxima Etapa"
            data-mobile=""
        />
    )
}