import { lazy, Suspense } from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';

import Loading from 'pages/components/Loading';

const HowToStudy = lazy(() => import('pages/HowToStudy'));
const LearningResources = lazy(() => import('pages/LearningResources'));
const UnitSummary = lazy(() => import('pages/UnitSummary'));
const Videoclass = lazy(() => import('pages/Videoclass'));
const StudyGuide = lazy(() => import('pages/StudyGuide'));
const SharingIdeas = lazy(() => import('pages/SharingIdeas'));
const ContributeDocument = lazy(() => import('pages/ContributeDocument'));
const DeepeningKnowledge = lazy(() => import('pages/DeepeningKnowledge'));
const EvaluateUnit = lazy(() => import('pages/EvaluateUnit'));
const PraticalClassScript = lazy(() => import('pages/PraticalClassScript'));
// const DisciplineEvaluationForm = lazy(() => import('pages/DisciplineEvaluationForm'));

const NotFound = lazy(() => import('pages/NotFound'));

export default function Authenticated() {
    return (
        <Suspense fallback={<Loading title="A página está sendo carregada..." />}>
            <Switch>
                <Route path="/" element={<Navigate to="/como-estudar" replace />}>
                    <Route path="/:idSubject" element={<Navigate to="/resumo-unidade" replace />} />
                </Route>

                {/* <Route path='avaliar-disciplina' element={<DisciplineEvaluationForm />}/> */}
                <Route path='como-estudar' element={<HowToStudy />} />
                <Route path='resumo-unidade' element={<UnitSummary />}/>
                <Route path='recursos-aprendizagem' element={<LearningResources />}/>
                <Route path='videoaulas/:idVideoclass' element={<Videoclass />}/>
                <Route path='aula-pratica/:idVideoclass' element={<Videoclass />}/>
                <Route path='guia-estudos' element={<StudyGuide />}/>
                <Route path='partilhando-ideias' element={<SharingIdeas />}/>
                <Route path='contribuindo-documento' element={<ContributeDocument />}/>
                <Route path='aprofundando-conhecimento' element={<DeepeningKnowledge />}/>
                <Route path='avaliar-unidade' element={<EvaluateUnit />}/>
                <Route path='roteiro-pratica' element={<PraticalClassScript />}/>
                
                <Route path='/:idSubject/ecossistema/:acess' element={<Navigate to="/como-estudar" />}/>

                <Route path='*' element={<NotFound />}/>
            </Switch>
        </Suspense>
    )
}
