import Toastify from "components/Toastify";
import { GetRegisterNotepadProps, IRegisterCommentContribute, IRegisterContributeProps, IRegisterRateContribute, IRegisterRateUnity, IRota } from "./types/unitys";
import RichTextEditor from "react-rte";
import { material_api } from "services";

export class UnitysServices {
    static ordenarArrayPorPosition(arr: any) {
        // Filtra os objetos com position não nulo
        const objetosComPosition = arr.filter((obj: any) => obj?.position !== null);
    
        // Filtra os objetos com position nulo
        const objetosSemPosition = arr.filter((obj: any) => obj?.position === null);
    
        // Ordena os objetos com position não nulo pelo valor da propriedade position
        objetosComPosition.sort((a: any, b: any) => a?.position - b?.position);
    
        // Combina os objetos ordenados com position não nulo e os objetos com position nulo
        const resultado = objetosComPosition.concat(objetosSemPosition);
    
        return resultado;
    }
    
    static async getUnitysByIdSubject(idSubject: string) {
        if (!idSubject) {
            return {}
        }
    
        const { data } = await material_api.get(`disciplina/${idSubject}/unidades`);
        return this.ordenarArrayPorPosition(data);
    }

    static async getPraticalClassScriptByIdUnity(idUnity: number | undefined) {
        if(!idUnity) {
            return {}
        }
    
        return await material_api.get(`unidade/${idUnity}/recurso-avaliacao`);
    }

    static async getDeepeningKnowledgeByIdUnity(idUnity: number | undefined) {
        if (!idUnity) {
            return []
        }
    
        const { data } = await material_api.get(`unidade/${idUnity}/aprofundando-conhecimento`);
        return data;
    }

    static async getNotepadByIdUnity(idUnity: number | undefined) {
        if (!idUnity) {
            return {}
        }
    
        const { data } = await material_api.get(`unidade/${idUnity}/bloco-de-notas`);
        return data;
    }

    static async getContributesDocumentByIdUnity(idUnity: number | undefined, paginator: number = 1, rate: string) {
        if (!idUnity) {
            return ''
        }
    
        let url = `unidade/${idUnity}/contribuicao/?page=${paginator}`;
    
        if (rate !== 'Todas') {
            url = `unidade/${idUnity}/contribuicao/avaliacao/${rate}/?page=${paginator}`;
        }
    
        const { data } = await material_api.get(url);
        return data;
    }

    static async getContributeDocumentById(idContribute: number | undefined) {
        if (!idContribute) {
            return {}
        }
    
        const { data } = await material_api.get(`unidade/1/contribuicao/${idContribute}`);
        return data;
    }

    static async getRatesByIdUnity(idUnity: number | undefined) {
        if (!idUnity) {
            return {}
        }
    
        const { data } = await material_api.get(`unidade/${idUnity}/avaliacao`);
        return data;
    }

    static async postRegisterNotepad({ texto, idUnity, whatAction }: GetRegisterNotepadProps) {
        if (!idUnity) {
            return {}
        }
    
        const rota: IRota = {
            url: `unidade/${idUnity}/bloco-de-notas`,
            method: whatAction === 'create' ? 'post' : 'put'
        }
    
        const { data } = await material_api[rota.method](rota.url, { texto });
        return data;
    }

    static async postRegisterContribute({ titulo, link, descricao, idUnity }: IRegisterContributeProps) {
        if (!idUnity) {
            return {}
        }
    
        const { data } = await material_api.post(`unidade/${idUnity}/contribuicao`, {
            titulo,
            link,
            descricao
        });
    
        return data;
    }

    static async postRegisterCommentContribute({ idContribute, comentario }: IRegisterCommentContribute) {
        if (!idContribute) {
            return {}
        }
    
        const { data } = await material_api.post(`unidade/1/contribuicao/${idContribute}/comentario`, { comentario });
        return data;
    }

    static async postRegisterRateUnity({ idUnity, rate, feedback }: IRegisterRateUnity) {
        if (!idUnity) {
            return {}
        }
    
        const { data } = await material_api.post(`unidade/${idUnity}/avaliacao`, {
            avaliacao: rate,
            feedback
        });
    
        return data;
    }

    static async putRegisterRate({ idContribute, avaliacao }: IRegisterRateContribute) {
        if (!idContribute || !avaliacao) {
            return {}
        }
    
        const { data } = await material_api.put(`unidade/1/contribuicao/${idContribute}/avaliacao`, { avaliacao });
    
        return data;
    }
}

// Funções
export const getUnitysByIdSubject = async (idSubject: string, setUnitys: any) => {
    try {
        const result = await UnitysServices.getUnitysByIdSubject(idSubject);
        setUnitys(result);
    } catch(error) {
        console.log(error);
    }
}

export const getPraticalClassScriptByIdUnity = async (idUnity: number | undefined, setScriptPratical: any) => {
    try {
        const { data }: any = await UnitysServices.getPraticalClassScriptByIdUnity(idUnity);
        setScriptPratical(data);
    } catch(error) {
        console.log(error);
    }
}

export const getDeepeningKnowledgeByIdUnity = async (idUnity: number | undefined, setDepeeningKnowledge: any) => {
    try {
        const result = await UnitysServices.getDeepeningKnowledgeByIdUnity(idUnity);
        setDepeeningKnowledge(result);
    } catch(error) {
        console.log(error);
    }
}

export const getNotepadByIdUnity = async (idUnity: number | undefined, setContent: any, setData: any) => {
    try {
        const result = await UnitysServices.getNotepadByIdUnity(idUnity);
        setContent(RichTextEditor.createValueFromString(result?.bloco?.texto ?? '', 'markdown'));
        setData(result);
    } catch(error) {
        console.log(error);
    }
}

export const getContributesDocumentByIdUnity = async (idUnity: number | undefined, paginator: number = 1, rate: string, setContributesDocument: any, setIsLoading: any) => {
    try {
        const result = await UnitysServices.getContributesDocumentByIdUnity(idUnity, paginator, rate);
        setContributesDocument(result);
    } catch(error) {
        console.log(error);
        setContributesDocument({});
    } finally {
        setIsLoading(false);
    }
}

export const getContributeDocumentById = async (idContribute: number | undefined, setContributeDocument: any, setIsLoading: any) => {
    try {
        const result = await UnitysServices.getContributeDocumentById(idContribute);
        setContributeDocument(result);
    } catch(error) {
        console.log(error);
    } finally {
        setIsLoading(false);
    }
}

export const getRatesByIdUnity = async (idUnity: number | undefined, setRatesUnity: any, setIsLoading: any) => {
    try {
        const result = await UnitysServices.getRatesByIdUnity(idUnity);
        setRatesUnity(result);
    } catch(error) {
        console.log(error);
    } finally {
        setIsLoading(false);
    }
}

export const postRegisterNotepad = async (values: GetRegisterNotepadProps, setIsLoading: any) => {
    try {
        await UnitysServices.postRegisterNotepad(values);
        Toastify({ message: 'Anotações Atualizada.', type: 'success' });
    } catch(error) {
        console.log(error);
    } finally {
        setIsLoading(false);
    }
}

export const postRegisterContribute = async (values: IRegisterContributeProps, reload: boolean, setIsLoading: any, setReload: any) => {
    try {
        await UnitysServices.postRegisterContribute(values);
        Toastify({ message: 'Contribuição realizada.', type: 'success' });
    } catch(error) {
        console.log(error);
    } finally {
        setIsLoading(false);
        setReload(!reload);
    }
}

export const postRegisterCommentContribute = async (values: IRegisterCommentContribute, reload: boolean, setIsLoading: any, setReload: any) => {
    try {
        await UnitysServices.postRegisterCommentContribute(values);
    } catch(error) {
        console.log(error);
    } finally {
        setIsLoading(false);
        setReload(!reload);
    }
}

export const postRegisterRateUnity = async (values: IRegisterRateUnity, reload: boolean, setIsLoading: any, setReload: any) => {
    try {
        await UnitysServices.postRegisterRateUnity(values);
        Toastify({ message: 'Obrigado por sua avaliação.', type: 'success' });
    } catch(error) {
        console.log(error);
    } finally {
        setIsLoading(false);
        setReload(!reload);
    }
}

export const putRegisterRate = async (values: IRegisterRateContribute, reload: boolean, setIsLoading: any, setReload: any) => {
    try {
        await UnitysServices.putRegisterRate(values);
        Toastify({ message: 'Obrigado por sua avaliação.', type: 'success' });
    } catch(error) {
        console.log(error);
    } finally {
        setIsLoading(false);
        setReload(!reload);
    }
}