import Pages from 'pages';
import { Route, Routes as Switch } from 'react-router-dom';

function App() {
  return (
    <Switch>
      <Route path='*' element={<Pages />} />
    </Switch>
  )
}

export default App;