import { useState, useEffect, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DataContext } from 'context/data';
import { INIT_VALUES_UNITYS } from 'context/data.init.values';
import {
    TScriptPratical,
    TSubject,
    TUnitys,
    TVideos,
    TOnHandleUpdateLogsProps,
    TLearningResources,
    TOnHandleUpdateLogsPropsType
} from 'context/data.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getSubjectById } from 'services/Subject';
import { getDeepeningKnowledgeByIdUnity, getPraticalClassScriptByIdUnity, getUnitysByIdSubject } from 'services/Unitys';
import { getVideosByIdUnity } from 'services/Videos';
import { getLearningResourcesByIdUnity } from 'services/LearningResources';
import { getLogsByRaAndIdSubjectAndIdIUnity, getProgressByDisciplineAndRa, postLogs } from 'services/Logs';
import { ILogsProps } from 'services/types/logs';
import { LoginServices } from 'services/Login';

export default function DataProvider({ children }: { children: ReactNode }) {
    const navigate = useNavigate();
    const [access, setAccess] = useState<{ idSubject: string, ra: string } | undefined>();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [theme, setTheme] = useState<'uninta' | 'fal' | 'neutral'>('neutral');
    const [logs, setLogs] = useState<ILogsProps | undefined | any>(undefined);

    const [totalProgress, setTotalProgress] = useState<number | string>(0);       // Progresso total da disciplina
    const [visited, setVisited] = useState<any | any[]>([]);          // Rotas visitadas pelo aluno          
    const [routes, setRoutes] = useState<any | any[]>([]);          // Rotas visitadas pelo aluno          
    let location = useLocation();

    /**
     * States que buscam e armazenam os dados para compartilhar entre os componentes
     */
    const [unitySelected, setUnitySelected] = useState<TUnitys>(INIT_VALUES_UNITYS);

    const [subject, setSubject] = useState<any>({});
    const [unitys, setUnitys] = useState<any[]>([]);
    const [videos, setVideos] = useState<any[]>([]);
    const [learningResources, setLearningResources] = useState<any[]>([]);
    const [scriptPratical, setScriptPratical] = useState<any>({});
    const [depeeningKnowledge, setDepeeningKnowledge] = useState<any[]>([]);
    const [dataLogs, setDataLogs] = useState<any>({});

    // const progressLogsDiscipline                                    = useGetProgressByDisciplineAndRa(access?.ra ?? '', access?.idSubject ?? '');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let laboratório = videos?.filter((video: any) => video?.type?.id === 6);
    const typeLogGab: any = {
        comoestudar: "ComoEstudar",
        resumounidade: "ResumoDaUnidade",
        recursosaprendizagem: "RecursosDeAprendizagem",
        videoaulas: "Videoaulas",
        guiaestudos: "GuiaDeEstudos",
        partilhandoideias: "PartilhandoIdeias",
        contribuindodocumento: "ContribuaComUmDocumento",
        aprofundandoconhecimento: "AprofundandoNoConhecimento",
        avaliarunidade: "AvaliarUnidade",
        roteiropratica: "RoteiroParaAulaPrática",
        aulapratica: "VídeosParaAulaPrática",
    }

    /**
     * @description Salva localização atual do usuário ao clicar em um novo step na navegação
     * @param path 
     * @param subpath 
     */
    // const handleActivedStepInSession = (path: number, subpath: number) => {
    //     sessionStorage.setItem('@md_AS', window.btoa(JSON.stringify({ path, subpath })));
    // }

    /**
     * Faz o calculo do progresso 
     */
    function calcTotalProgress() {
        var path = {}

        if (routes && routes.length > 0) {
            if (visited && visited.length > 0) {
                var soma = 0;
                // eslint-disable-next-line array-callback-return
                routes.map((route: any) => {
                    var aux_visit = visited.find((visit: any) => (visit?.id_unidade === route?.id_unidade));
                    path = {
                        ...path,
                        [route?.id_unidade]: (aux_visit?.rotas?.length) / route?.routes?.length,
                    }
                    localStorage.setItem("@path", JSON.stringify(path))
                })
                Object.values(path).forEach((item: any) => soma += item)
                localStorage.setItem("@routes", JSON.stringify(routes))
                localStorage.setItem("@visited", JSON.stringify(visited))

                return Number(((soma * 100) / unitys.length).toFixed(2).toString())
            }
        }
    }

    /**
     * 
     * @param {number|string}                               unity Unidade atual
     * @param {url: string, path: string; subpath: string}  route Rota atual (onde o usuário clicou)
     */
    function insertRouteToUnity(unity: number | undefined, route: { url: any; path?: number; subpath?: number; }) {
        // Como-estudar só existe na primeira unidade do material didático
        if (unity !== unitys[0].id && route.url === "/como-estudar") {
            return;
        }
        // Adiciona o elemento de navegação ao contexto de BookContext

        var visited_unity: { rotas: { url: any; path?: number | undefined; subpath?: number | undefined; }[]; id_unidade: any; } | null = null;
        // var visited_unity: {id_unidade: string|number; rotas: any[]}[]|[]  = [];
        var visited_route: any = [];

        visited_unity = visited.find((unidade: { id_unidade: any }) => {
            return unidade.id_unidade === unity
        })


        if ((visited_unity === undefined || visited_unity === null) && unitySelected) {
            setVisited([{
                id_unidade: unitySelected.id,
                titulo_unidade: unitySelected.titulo_unidade,
                rotas: [route]
            }]);
        }

        if (visited_unity !== undefined && visited_unity !== null && visited_unity.rotas) {
            if (visited_unity.rotas) {
                visited_route = visited_unity.rotas.find((rota: { url: string; }) => {
                    return rota.url === route.url
                })
            }
        }

        if (visited_unity !== undefined && visited_unity !== null && visited_route === undefined) {
            var temp: any = visited_unity
            temp.rotas.push(route)

            // console.log(temp)

            // if(visited_unity.id_unidade !== null) {
            //     var visited_items = visited.filter((visited_item: { id_unidade: any; }) => {
            //     return visited_item.id_unidade !== visited_unity!.id_unidade
            //     })
            // }

            setVisited([temp])
        }

        calcTotalProgress();
        return;
    }

    /** 
     * VERIFICA SE O STEP CLICADO JÁ FOI ADICIONADO AOS LOGS 
     * */
    const onValidateUpdateLogs = ({ typeLog, resource }: TOnHandleUpdateLogsProps) => {
        if (typeLog !== 'RecursosDeAprendizagem'
            && typeLog !== 'AprofundandoNoConhecimento'
            && typeLog !== 'Videoaulas'
            && typeLog !== 'VídeosParaAulaPrática'
            && typeLog !== 'BotaoResumoVideo'
        ) {
            if (Boolean(logs?.log_info) && logs?.log_info[typeLog]) return false;
        }
        if ((typeLog === 'RecursosDeAprendizagem' && logs?.log_info['RecursosDeAprendizagem']?.sort().join(',') === resource!.sort().join(','))
            || (typeLog === 'AprofundandoNoConhecimento' && logs?.log_info['AprosfundandoNoConhecimento']?.sort().join(',') === resource!.sort().join(','))
            || (typeLog === 'Videoaulas' && logs?.log_info['Videoaulas']?.includes(...resource!))
            || (typeLog === 'VídeosParaAulaPrática' && logs?.log_info['VídeosParaAulaPrática']?.includes(...resource!))
            || (typeLog === 'BotaoResumoVideo' && logs?.log_info['BotaoResumoVideo']?.includes(...resource!))
        ) {
            return false;
        }
        return true;
    }

    /** 
     * @description Atualiza o estado dos logs
     * @param {any} typeLog
     * @param {any} resource
     * @param {any} unity
     * 
     * FIXME: Reutilizar este método para manipular o log em alto nível
     * */
    function onHandleUpdateLogs({ typeLog, resource }: TOnHandleUpdateLogsPropsType) {
        if (onValidateUpdateLogs({ typeLog: typeLog, resource: resource ? resource : false })) {
            setLogs((oldValues: any) => {
                return ({
                    ...oldValues!,
                    log_info: {
                        ...oldValues?.log_info,
                        percent_progress: Number(calcTotalProgress()),
                        visited: visited,
                        [typeLog as string]:
                            resource
                                ? oldValues?.log_info[typeLog!]
                                    ? [...oldValues?.log_info[typeLog!] as any[], ...resource]
                                    : [...resource]
                                : true,
                    },
                })
            });
        }
    }

    /*
     * Função para ser executada ao clicar no botão "Próxima Etapa" e o usuário ser direcionado para a etapa seguinte
     * FIXME: Função nextStep nova e funcional, faltando passar o routes e setRoutes para esse contexto e adicionar os logs apos o uso da função
     */
    const nextStep = () => {
        // eslint-disable-next-line array-callback-return
        routes.map((unity: any) => {
            if (unitySelected.id === unity.id_unidade) {
                // eslint-disable-next-line array-callback-return
                unity.routes.map((obj: any, index: number) => {
                    if (location.pathname === obj.url) {
                        if (index + 1 === unity.routes.length) {
                            if (Number(unitys[unitys.length - 1].id) !== Number(unitySelected.id)) {
                                setUnitySelected(unitys[unitys?.indexOf(unitySelected) + 1]);
                                navigate("/resumo-unidade");
                                onHandleUpdateLogs({ typeLog: "ResumoDaUnidade" })
                            }
                        } else {
                            navigate(unity.routes[index + 1].url);
                            insertRouteToUnity(unitySelected.id, {
                                url: unity.routes[index + 1].url,
                                path: unity.routes[index + 1].pathActived,
                                subpath: unity.routes[index + 1]?.subpathActived || 0
                            });

                            if (unity.routes[index + 1].pathActived === 2 || unity.routes[index + 1].pathActived === 7) {
                                onHandleUpdateLogs({
                                    // eslint-disable-next-line no-useless-escape
                                    typeLog: typeLogGab[unity.routes[index + 1].url.replace(/[\/-\d]/g, '')],
                                    resource: unity.routes[index + 1].subpathActived
                                })
                            } else {
                                onHandleUpdateLogs({
                                    // eslint-disable-next-line no-useless-escape
                                    typeLog: typeLogGab[unity.routes[index + 1].url.replace(/[\/-\d]/g, '')],
                                    resource: unity.routes[index + 1].url.match(/\d+/g) !== null ? [Number(unity.routes[index + 1].url.match(/\d+/g))] : false
                                })
                            }
                        }
                    }
                });
            }
        });
    }

    useEffect(() => {
        getSubjectById(access?.idSubject ?? '', setSubject);
        getUnitysByIdSubject(access?.idSubject ?? '', setUnitys);
    }, [access?.idSubject]);

    useEffect(() => {
        if (unitySelected.id) {
            getVideosByIdUnity(unitySelected?.id, setVideos);
            getLearningResourcesByIdUnity(unitySelected?.id, setLearningResources);
            getPraticalClassScriptByIdUnity(unitySelected?.id, setScriptPratical);
            getDeepeningKnowledgeByIdUnity(unitySelected?.id, setDepeeningKnowledge);
        }
    }, [unitySelected?.id]);

    useEffect(() => {
        if (access?.ra && unitySelected.id) {
            getLogsByRaAndIdSubjectAndIdIUnity(access?.ra ?? '', unitySelected?.id, setDataLogs, false);
        }
    }, [access?.ra, unitySelected?.id]);

    /**
     * Este efeito buscará os dados de sessão que contém o id da disciplina acessada
     */
    useEffect(() => {
        const storageLocal = sessionStorage.getItem('@md_S') as string;

        let response = LoginServices.verifyAccess(storageLocal, setAccess);

        if (response) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
        // eslint-disable-next-line
    }, [sessionStorage.getItem('@md_S')]);

    /**
     * Este efeito buscará o tema
     */
    useEffect(() => {
        const storageSession: any = sessionStorage.getItem('@md_T');

        if (storageSession) {
            setTheme(window.atob(storageSession) as 'fal' | 'uninta' | 'neutral');
        }
        // eslint-disable-next-line
    }, [sessionStorage.getItem('@md_T')]);

    /**
     * Este efeito seta o valor da primeira unidade do array que o fetch busca, dentro do estado de unidade selecionada.
     * obs: isso é necessário para buscar os vídeos da primeira unidade inicial.
     */
    useEffect(() => {
        if (Array.isArray(unitys) && unitys.length > 0) {
            setUnitySelected(unitys[0]);
        }
    }, [unitys]);

    /**
     * Ao recarregar a página busca os logs de progresso do usuário
     */
    useEffect(() => {
        getLogsByRaAndIdSubjectAndIdIUnity(access?.ra ?? '', unitySelected?.id, setDataLogs, true)
            .then((data: any) => {
                if (data !== "") {
                    const { createdAt, updatedAt, _id, __v, ...logsData } = data;
                    setLogs(logsData);
                    setVisited(data.log_info?.visited ? data.log_info?.visited : []);
                } else {
                    if (unitySelected.id === unitys[0].id) {
                        setLogs({
                            ra: access?.ra ?? '',
                            id_disciplina: access?.idSubject ?? '',
                            id_unidade: unitySelected.id,
                            log_info: {
                                ComoEstudar: true,
                                percent_progress: 0,
                                visited: [{
                                    id_unidade: unitySelected.id,
                                    titulo_unidade: unitySelected.titulo_unidade,
                                    rotas: [{ "url": "/como-estudar", "path": 0, "subpath": 0 }]
                                }],
                            }
                        });

                        setVisited([{
                            id_unidade: unitySelected.id,
                            titulo_unidade: unitySelected.titulo_unidade,
                            rotas: [{ "url": "/como-estudar", "path": 0, "subpath": 0 }]
                        }]);

                    } else if (unitySelected.id !== unitys[0].id) {
                        setLogs({
                            ra: access?.ra ?? '',
                            id_disciplina: access?.idSubject ?? '',
                            id_unidade: unitySelected.id,
                            log_info: {
                                ResumoDaUnidade: (Number(unitys[0].id) !== Number(unitySelected.id)),
                                percent_progress: 0,
                                visited: [{
                                    id_unidade: unitySelected.id,
                                    titulo_unidade: unitySelected.titulo_unidade,
                                    rotas: [{ "url": "/como-estudar", "path": 0, "subpath": 0 }]
                                }],
                            }
                        });

                        setVisited([{
                            id_unidade: unitySelected.id,
                            titulo_unidade: unitySelected.titulo_unidade,
                            rotas: [{ "url": "/resumo-unidade", "path": 1, "subpath": 0 }]
                        }]);
                    }
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unitySelected, access]);

    // useEffect(() => {
    //     if ((
    //             ((!dataLogs.data && !logs) 
    //                 || (Number(unitySelected?.id) !== Number(logs?.id_unidade)))
    //                 && visited.length !== 0 
    //                 && visited[0].id_unidade === unitySelected.id
    //         ))
    //     {

    //         // setLogs({
    //         //     ra: access?.ra ?? '',
    //         //     id_disciplina: access?.idSubject ?? '',
    //         //     id_unidade: unitySelected.id,
    //         //     log_info: { 
    //         //         ComoEstudar: true,
    //         //         ResumoDaUnidade: (Number(unitys[0].id) !== Number(unitySelected.id)),
    //         //         percent_progress: calcTotalProgress(),
    //         //         visited: visited,
    //         //      }
    //         // });
    //     }
    //     // eslint-disable-next-line
    // }, [dataLogs.data, visited]);

    /**
     * Atualiza os logs sempre que o estado dos
     * logs atualizar
     */
    useEffect(() => {
        if (!!logs && logs?.log_info?.percent_progress > 0 && Object.entries(logs.log_info).length > 1) {
            postLogs({ ...logs! });
        }
        setTimeout(() => {
            getProgressByDisciplineAndRa(access?.ra ?? '', access?.idSubject ?? '', setTotalProgress);
        }, 1000);
        // eslint-disable-next-line
    }, [logs]);

    /**
    * Busca o percentual de progresso na disciplina,
    * quando a tela é carregada ou recarregada
    */
    useEffect(() => {
        if (access?.idSubject && access?.ra) {
            getProgressByDisciplineAndRa(access?.ra ?? '', access?.idSubject ?? '', setTotalProgress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // access?.idSubject, access?.ra, unitySelected, logs
    ])

    return (
        <DataContext.Provider
            value={{
                theme,
                isAuthenticated,
                setIsAuthenticated,
                ra: access?.ra ?? '',
                subject: subject as TSubject,
                setSubject,
                unitys: unitys as TUnitys[],
                learningResources: learningResources as TLearningResources[],
                videos: videos as TVideos[],
                pratic_videos: laboratório as TVideos[],
                script_pratical: scriptPratical as TScriptPratical | any,
                depeeningKnowledge: depeeningKnowledge as any,
                dataLogs: dataLogs as any,
                unitySelected,
                setUnitySelected,
                nextStep,
                logs,
                onHandleUpdateLogs,
                onValidateUpdateLogs,
                totalProgress,
                setTotalProgress,
                visited,
                setVisited,
                routes,
                setRoutes,
                insertRouteToUnity
            }}
        >
            {children}
        </DataContext.Provider>
    )
}