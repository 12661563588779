import { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import HomeOutlined from "@mui/icons-material/HomeOutlined";
import Menu from "@mui/icons-material/Menu";

import SidebarMobile from "../SidebarMobile";
import BtnNextStep from "./BtnNextStep";

import useDataContext from "context/data.hook";

import './styles.scss';

export function onGoEcossistema() {
  sessionStorage.removeItem('@md_S');
  sessionStorage.removeItem('@md_AS');

  window.location.href =  sessionStorage.getItem("parent_url") ?? `${process.env.REACT_APP_URL_ECOSSISTEMA}`
}

export default function Header(){
  const { theme, isAuthenticated } = useDataContext();
  
  const [openMenuSidebarMobile, setOpenMenuSidebarMobile] = useState(false);

  return (
    <AppBar className="header">
        <Toolbar className="content">
          <Box component="div">
            {
              !!isAuthenticated && (
                <Box className="area_btn_header">
                  <IconButton
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                    onClick={onGoEcossistema}
                  >
                    <HomeOutlined />
                  </IconButton>

                  <IconButton
                    sx={{ display: { md: 'none' } }}
                    onClick={() => setOpenMenuSidebarMobile(!openMenuSidebarMobile)}
                  >
                    <Menu />
                  </IconButton>
                </Box>
              )
            }
            {theme !== 'neutral' ?
            <>{theme === 'fal' ? 
                  <img src="https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5+-+logos+2-07.svg" alt="Logo da faculdade 5 de julho branca" />
                : <img src="/uninta.svg" alt="Logo do UNINTA vermelha"/>
              }</> :<></>}
            
          </Box>
          
          {
            !!isAuthenticated && <BtnNextStep />
          }
        </Toolbar>

        <SidebarMobile open={openMenuSidebarMobile} setOpen={setOpenMenuSidebarMobile}/>
    </AppBar>
  );
}