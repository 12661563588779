import axios from "axios";
import Toastify from "components/Toastify";
import { ecossistema_api } from "services";
import { SingInProps } from "./types/login";

export class LoginServices {
    static async authenticationMyLibrary(props: { username: string, password: string }) {
        return await axios.post(
            `https://minhabiblioteca.aiamis.com.br/api/companies/1/access`, { ...props }
        );
    }

    static async signIn(values: SingInProps) {
        return await ecossistema_api.post('nti/login', values);
    }

    static verifyAccess(access: any, setAccess: any) {
        let params: any = sessionStorage.getItem('@params');
        let response = null;

        if(access && params) {
            params = JSON.parse(params);
            access = JSON.parse(window.atob(access));

            if (params?.idSubject === access?.idSubject) {
                response = true;
                setAccess({ ...access });
            } else {
                response = true;
                const { idSubject, ra } = params;
                setAccess({ ...access, idSubject, ra });
            }

        // O caso abaixo é para não ocorrer erro no desenvolvimento ao efetuar o login sem passar o params na url.
        } else if(access && (params === null)) {
            access = JSON.parse(window.atob(access));
            response = true;
            setAccess({ ...access });
        }

        return response;
    }
}

export const authenticationMyLibrary = async (props: { username: string, password: string }) => {
    try {
        return await LoginServices.authenticationMyLibrary(props);
    } catch (error) {
        console.log(error);
    }
}

export const signIn = async (values: SingInProps, setIsLoading: any) => {
    try {
        const { data } = await LoginServices.signIn(values);

        const { ra, idSubject } = values;
        const sessionData = { ...data, ra, idSubject };

        Toastify({ message: 'Seja Bem-vindo e bons Estudos', type: 'success' });
        sessionStorage.setItem('@md_S', window.btoa(JSON.stringify(sessionData)));

        window.location.href = '/como-estudar';
    } catch (error) {
        console.log(error);
        Toastify({ message: 'RA e/ou senha incorretos.', type: 'warn' });
    } finally {
        setIsLoading(false);
    }
}