import { material_api } from "services";

export class VideosServices {
    static async getVideosByIdUnity(idUnity: number) {
        if (!idUnity) {
            return []
        }

        const { data } = await material_api.get(`unidade/${idUnity}/video-aulas`, {
            headers: {
                'Content-Disposition': 'attachment',
                'Content-Type': 'application/octet-stream'
            },
        });

        return data;
    }
    
    static async getPraticVideosByIdUnity(idUnity: number | undefined) {
        if (!idUnity) {
            return []
        }

        const { data } = await material_api.get(`unidade/${idUnity}/video-praticas`);

        return data;
    }
}

//Funções
export const getVideosByIdUnity = async (idUnity: number, setVideos: any) => {
    try {
        const result = await VideosServices.getVideosByIdUnity(idUnity);
        setVideos(result);
    } catch(error) {
        console.log(error);
    }
}

export const getPraticVideosByIdUnity = async (idUnity: number | undefined, setVideos: any) => {
    try {
        const result = await VideosServices.getPraticVideosByIdUnity(idUnity);
        setVideos(result);
    } catch(error) {
        console.log(error);
    }
}