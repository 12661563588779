import { material_api } from "services";

export class LearningResourcesServices {
    static async getLearningResourcesByIdUnity(idUnity: number) {
        if (!idUnity || idUnity === undefined) {
            return []
        }
    
        const { data } = await material_api.get(`unidade/${idUnity}/recursos-aprendizagem`);
        return data;
    }
}

//Funções
export const getLearningResourcesByIdUnity = async (idUnity: number, setLearningResources: any) => {
    try {
        const result = await LearningResourcesServices.getLearningResourcesByIdUnity(idUnity);
        setLearningResources(result);
    } catch(error) {
        console.log(error);
    }
}