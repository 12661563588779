import Toastify from "components/Toastify";
import { material_api } from "services";

export class SubjectServices {
    static async getSubjectById(idSubject: string) {
        if (!idSubject) {
            return {}
        }

        const { data } = await material_api.get(`disciplina/${idSubject}`);
        return data;
    }

    static async getQuizEvaluation(idSubject: string) {
        if (!idSubject) {
            return {}
        }

        const { data } = await material_api.get(`disciplina/${idSubject}/avaliar`);
        return data;
    }

    static async postQuizDisciplineEvaluates(values: { respostas: {}, idSubject: string }) {
        const { data } = await material_api.post(`disciplina/${values.idSubject}/avaliar`, values);
        return data;
    }
}

//Funções
export const getSubjectById = async (idSubject: string, setSubject: any, setLoadingSubject: any = null) => {
    try {
        const result = await SubjectServices.getSubjectById(idSubject);
        setSubject(result);
    } catch (error) {
        console.log(error);
    } finally {
        if (setLoadingSubject !== null) {
            setLoadingSubject(false);
        }
    }
}

export const getQuizEvaluation = async (idSubject: string, setIsLoading: any, setQuizEvaluation: any) => {
    try {
        const result = await SubjectServices.getQuizEvaluation(idSubject);
        setQuizEvaluation(result);
    } catch (error) {
        console.log(error);
    } finally {
        setIsLoading(false);
    }
}

export const postQuizDisciplineEvaluates = async (values: { respostas: {}, idSubject: string }, setLoadingSendQuiz: any) => {
    try {
        await SubjectServices.postQuizDisciplineEvaluates(values);
        Toastify({ message: 'Agradecemos por sua avaliação', type: 'success' });
    } catch (error) {
        console.log(error);
    } finally {
        setLoadingSendQuiz(false);
    }
}